import { _ as __decorate } from './_tslib-b3b41ee7.js';
import { css, LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { a as attachFocusMonitor, r as removeFocusMonitor } from './focus-visible-c6646cb2.js';

var css_248z$3 = css`:host{font-size:var(--header-font-size);line-height:var(--header-line-height);font-weight:var(--header-font-weight);letter-spacing:var(--header-letter-spacing);display:block}`;

let NxtHeader = class NxtHeader extends LitElement {
    static get styles() {
        return css_248z$3;
    }
    render() {
        return html ` <slot></slot> `;
    }
};
NxtHeader = __decorate([
    customElement('nxt-header')
], NxtHeader);

var css_248z$2 = css`:host{display:flex;align-items:center;padding:1px 32px 0;border-bottom:1px solid var(--header-border-color);height:64px;justify-content:space-between;background:var(--header-background-color)}@media screen and (-ms-high-contrast:active){:host{background-color:window}}slot[name=brand]{margin-right:32px;display:flex;align-items:center}::slotted([slot=actions]){margin-left:32px;display:flex;align-items:center}`;

let NxtHeaderRow = class NxtHeaderRow extends LitElement {
    static get styles() {
        return css_248z$2;
    }
    render() {
        return html `
      <slot name="brand"></slot>
      <slot name="navigation"></slot>
      <slot name="actions"></slot>
    `;
    }
};
NxtHeaderRow = __decorate([
    customElement('nxt-header-row')
], NxtHeaderRow);

var css_248z$1 = css`:host{flex:1 1 auto;display:flex;align-items:center}::slotted(ul){list-style:none;display:flex;padding:0}`;

let NxtHeaderNavigation = class NxtHeaderNavigation extends LitElement {
    constructor() {
        super(...arguments);
        this.role = 'navigation';
    }
    static get styles() {
        return css_248z$1;
    }
    render() {
        return html ` <slot></slot> `;
    }
};
__decorate([
    property({ reflect: true })
], NxtHeaderNavigation.prototype, "role", void 0);
NxtHeaderNavigation = __decorate([
    customElement('nxt-header-navigation')
], NxtHeaderNavigation);

var css_248z = css`:host{display:block;margin-right:32px}:host-context([dir=rtl]) :host{margin-right:0;margin-left:32px}:host:after{display:block;content:attr(text-content);height:1px;overflow:hidden;visibility:hidden}:host([active=true]) ::slotted(a),:host:after{font-weight:var(--header-link-active-font-weight)}:host([active=true]) ::slotted(a){color:var(--header-link-active-color)!important;border-color:currentColor}::slotted(a){padding-top:4px;border-bottom:4px solid transparent;text-decoration:none;white-space:nowrap}::slotted(a:focus){outline:0}:host([nxt-focus-visible]) ::slotted(a:focus){border-radius:4px;outline:0;box-shadow:var(--focus-box-shadow)}@media screen and (-ms-high-contrast:active){:host([nxt-focus-visible]) ::slotted(a:focus){box-shadow:0 0 0 2px background,0 0 0 6px windowText;outline:4px solid CanvasText;outline-offset:2px}}::slotted(a:active),::slotted(a:hover){color:var(--header-link-active-color)!important;border-color:var(--header-link-active-color)}`;

let NxtHeaderLink = class NxtHeaderLink extends LitElement {
    static get styles() {
        return css_248z;
    }
    constructor() {
        super();
        attachFocusMonitor(this);
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        removeFocusMonitor(this);
    }
    render() {
        return html ` <slot></slot> `;
    }
};
NxtHeaderLink = __decorate([
    customElement('nxt-header-link')
], NxtHeaderLink);

export { NxtHeader as N, NxtHeaderRow as a, NxtHeaderNavigation as b, NxtHeaderLink as c };
